import React from "react";
import { Row, Col, Card, Typography } from "antd";
import {} from "@ant-design/icons";
import { observer, inject } from "mobx-react";

import Login from "./Login";
import sideImage from "../../assets/images/Together.png";

@inject("userStore", "tokenStore", "sectionsStore")
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({});

    this.state = this.initialState();
  }

  render() {
    const { tokenStore, to } = this.props;
    if (`${tokenStore.value}`.trim() !== "") {
      return to;
    }
    return (
      <Row
        style={{ position: "absolute", height: "100%", width: "100%" }}
        type="flex"
        align="middle"
      >
        <Col
          md={12}
          sm={0}
          xs={0}
          style={{ backgroundColor: "#1e2832", height: "100%" }}
        >
          <div
            style={{
              height: "100%",
              position: "absolute",
              width: "80%",
              right: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundImage: `url('${sideImage}')`,
              backgroundSize: "contain",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Row justify="center" align="middle">
            <Col span={24}>
              <Typography.Title
                align="center"
                color="primary"
                style={{ color: "#C48411", letterSpacing: "1.5px" }}
              >
                DAS Food
              </Typography.Title>
            </Col>
            <Col span={22}>
              <Card bordered={false}>
                <Login />
              </Card>
            </Col>
            <Col span={24}>
              <Row
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  bottom: "-5rem",
                }}
              >
                <Col span={24}>
                  <Typography.Paragraph align="center" type="secondary">
                    <span>Copyright Act ©️  </span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://technobase.krd"
                      title="TechnoBase"
                      style={{ color: "#C48411" }}
                    >
                      TechnoBase,
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/retroomedia/"
                      title="TechnoBase"
                      style={{ color: "#C48411" }}
                    >
                      Retro Media Agency
                    </a>
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LoginManager;
