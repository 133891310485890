import React, { Suspense } from "react";

import { observer, inject } from "mobx-react";
import { Layout, Menu, Button, Col, Popover, Avatar, List } from "antd";
import {
  MenuOutlined,
  ShopOutlined,
  CreditCardOutlined,
  TagOutlined,
  FacebookOutlined,
  PhoneOutlined,
  EyeOutlined,
  MessageOutlined,
  MailOutlined,
  UserAddOutlined,
  AimOutlined,
  PlayCircleOutlined,
  ContactsOutlined,
  MacCommandOutlined,
} from "@ant-design/icons";

import { Switch, Route, Link, withRouter } from "react-router-dom";
import Loading from "../basic/Loading";
import routes from "./dashboardRoutes";
import largeLogo from "../../assets/images/logo2.png";
import smallLogo from "../../assets/images/logo2.png";

import "./dashboard.css";

const { Header, Content, Sider } = Layout;

@observer
@inject("userStore", "tokenStore", "sectionsStore")
class Dashboard extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      collapsed: false,
      expandedWithToggler: true,
      imageId: "",
    });
    this.state = this.initialState();
    this.onCollapse = (collapsed) => {
      this.setState({ collapsed });
    };
    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
        expandedWithToggler: true,
      }));
    };
    this.expandSidebar = () => {
      const { collapsed } = this.state;
      if (collapsed) {
        this.setState({ collapsed: false, expandedWithToggler: false });
      }
    };
    this.collapseSidebar = () => {
      const { expandedWithToggler } = this.state;
      if (!expandedWithToggler) this.setState({ collapsed: true });
    };
    this.logout = () => {
      Promise.all([
        // eslint-disable-next-line react/destructuring-assignment
        this.props.userStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.tokenStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.sectionsStore.clear(),
      ]).then(() => {});
    };
  }

  render() {
    const { collapsed, imageId } = this.state;
    const { location } = this.props;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <style>
          {`
          .ant-menu-item-selected{
            background-color: #1f2533 !important;
          }
        `}
        </style>
        <Sider
          theme="dark"
          onMouseEnter={this.expandSidebar}
          onMouseLeave={this.collapseSidebar}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          style={{
            height: "100vh",
            left: 0,
            position: "fixed",
            overflow: "auto",
            background: "#2d3546",
          }}
          width={240}
        >
          <div
            className="logo"
            style={{
              minHeight: 70,
              textAlign: "center",
              paddingTop: 20,
              background: "#1f2532",
            }}
          >
            {collapsed ? (
              <img
                alt="technoBase-logo"
                style={{ width: 45, marginBottom: 5 }}
                src={smallLogo}
              />
            ) : (
              <img
                alt="technoBase-logo"
                style={{ width: 140 }}
                src={largeLogo}
              />
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ background: "#2d3646", marginTop: 35 }}
          >
            <Menu.Item key="/user">
              <UserAddOutlined />
              <span>User</span>
              <Link to="/user" />
            </Menu.Item>
            <Menu.Item key="/product">
              <ShopOutlined />
              <span>Products</span>
              <Link to="/product" />
            </Menu.Item>
            <Menu.Item key="/card">
              <CreditCardOutlined />
              <span>Cards</span>
              <Link to="/card" />
            </Menu.Item>
            <Menu.Item key="/about">
              <TagOutlined />
              <span>About</span>
              <Link to="/about" />
            </Menu.Item>
            <Menu.Item key="/socials">
              <FacebookOutlined />
              <span>Socials</span>
              <Link to="/socials" />
            </Menu.Item>
            <Menu.Item key="/contact">
              <PhoneOutlined />
              <span>Contact</span>
              <Link to="/contact" />
            </Menu.Item>
            <Menu.Item key="/chairman">
              <MessageOutlined />
              <span>Chairman</span>
              <Link to="/chairman" />
            </Menu.Item>
            <Menu.Item key="/ceo">
              <ContactsOutlined />
              <span>CEO</span>
              <Link to="/ceo" />
            </Menu.Item>
            <Menu.Item key="/ourvision">
              <EyeOutlined />
              <span>Our Vision</span>
              <Link to="/ourvision" />
            </Menu.Item>
            <Menu.Item key="/ourMission">
              <AimOutlined />
              <span>Our Mission</span>
              <Link to="/ourMission" />
            </Menu.Item>
            <Menu.Item key="/contactus">
              <MailOutlined />
              <span>Contact-Us-History</span>
              <Link to="/contactus" />
            </Menu.Item>
            <Menu.Item key="/vedio">
              <PlayCircleOutlined />
              <span>Landing Video</span>
              <Link to="/vedio" />
            </Menu.Item>
            <Menu.Item key="/galary">
              <MacCommandOutlined />
              <span>Gallery</span>
              <Link to="/galary" />
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: "all 0.2s",
            marginLeft: collapsed ? 80 : 240,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: "inherit" }}
          >
            <Col span={1} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: "#1f2532" }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col offset={21} span={2}>
              <Popover
                content={
                  <List>
                    <List.Item>
                      <a href="# " onClick={this.logout}>
                        Logout
                      </a>
                    </List.Item>
                  </List>
                }
                trigger="hover"
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Avatar src="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png" />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: "16px 16px", marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
