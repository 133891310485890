import React from "react";

import Dashboard from "./components/layout/dashboard";
import AuthGuard from "./components/AuthGuard";
import "./global.css";

function App() {
  return (
    <AuthGuard>
      <Dashboard />
    </AuthGuard>
  );
}

export default App;
