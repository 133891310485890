import superagent from 'superagent';
import superagentAbsolute from 'superagent-absolute';
import notificationHandler from './notificationHandler';
import store from '../store/index';

export default function getAgentInstance() {
  let agent = superagent.agent();
  agent.on('error', (err) => {
    notificationHandler(err);
  });

  agent = agent.set('Content-Type', 'application/json');
  agent = agent.set('authorization', `Bearer ${store.tokenStore.value}`);
  agent = agent.type('json');

  const request = superagentAbsolute(agent)(
    `${process.env.REACT_APP_API_LINK}`
  );
  return request;
}
