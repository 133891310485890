import { lazy } from "react";

const options = [
  {
    key: Math.random(),
    path: "/",
    component: lazy(() => import("../landing/land")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/product",
    component: lazy(() => import("../product/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/galary",
    component: lazy(() => import("../galary/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/card",
    component: lazy(() => import("../card/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/about",
    component: lazy(() => import("../about/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/socials",
    component: lazy(() => import("../socials/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/contact",
    component: lazy(() => import("../contact/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/ourvision",
    component: lazy(() => import("../ourvision/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/ourMission",
    component: lazy(() => import("../ourMission/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/chairman",
    component: lazy(() => import("../chairman/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/ceo",
    component: lazy(() => import("../ceo/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/contactus",
    component: lazy(() => import("../contactus/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/user",
    component: lazy(() => import("../user/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/vedio",
    component: lazy(() => import("../vedio/List")),
    exact: true,
  },
];
export default options;
