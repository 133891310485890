import React from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { observer, inject } from "mobx-react";
import getAgentInstance from "../../helpers/superagent";

const superagent = getAgentInstance();
const toastKey = "str";
@inject("userStore", "tokenStore", "sectionsStore")
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      data: [],
      loading: false,
    });
    this.state = this.initialState();

    this.onFinish = (values) => {
      this.setState({ loading: true });
      superagent
        .post(`${process.env.REACT_APP_API_LINK}/auth/signin`)
        .send({ Email: values.username, Password: values.password }) // sends a JSON post body
        .set("Content-Type", "application/json")
        .type("json")
        .end((err, res) => {
          this.setState({ loading: false });
          if (err) {
            message.error({ content: "Login Failed!", key: toastKey });
          } else {
            const { body } = res;
            if (body.status === "success") {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.userStore.value = body.data.user;
              // eslint-disable-next-line react/destructuring-assignment
              this.props.sectionsStore.value = body.data.sections;
              // eslint-disable-next-line react/destructuring-assignment
              this.props.tokenStore.value = body.data.token;
            } else {
              //   message.error({ content: body.msg, key: toastKey });
            }

          }  
          this.setState({ loading: false });
        });
      //
    };
  }

  render() {
    const { loading } = this.state;

    return (
      <Form layout="vertical" onFinish={this.onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="username"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={4} offset={20}>
            <Button
              htmlType="submit"
              block
              type="primary"
              style={{ backgroundColor: "#C48411", borderColor: "#C48411" }}
              loading={loading}
            >
              Login
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Login;
